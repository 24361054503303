export default function RocketSvg({ className }) {
    return (
        <svg
            className={className}
            version="1.1"
            viewBox="0 0 512 512"
            width="440"
        >
            <g className="rocket-group">
                <path
                    d="M 0.00 0.00 L 512.00 0.00 L 512.00 512.00 L 0.00 512.00 L 0.00 0.00 Z"
                    fill="transparent"
                    opacity="1.00"
                    stroke="transparent"
                    strokeWidth="0.25"
                    id="path2"
                />
                <path
                    d="M 254.89 20.56 C 255.47 20.53 256.05 20.49 256.63 20.46 C 256.34 20.60 255.77 20.88 255.48 21.02 C 255.89 23.64 256.38 26.26 256.30 28.93 C 256.00 45.44 256.52 61.97 256.03 78.48 C 250.75 78.87 245.45 78.73 240.17 78.49 C 242.20 66.65 243.62 54.65 247.16 43.14 C 248.61 37.80 250.66 32.66 252.26 27.37 C 252.67 26.04 252.94 24.66 253.31 23.32 C 253.67 22.32 254.36 21.48 254.89 20.56 Z"
                    fill="#e31c33"
                    opacity="1.00"
                    stroke="#e31c33"
                    strokeWidth="0.25"
                    id="path4"
                />
                <path
                    d="M 255.48 21.02 C 255.77 20.88 256.34 20.60 256.63 20.46 C 269.74 35.84 277.21 55.29 281.80 74.77 C 281.99 76.26 282.17 77.76 282.34 79.26 C 273.63 79.12 264.93 79.18 256.22 79.19 C 247.34 79.24 238.46 79.14 229.57 79.23 C 229.55 78.73 229.51 77.73 229.49 77.24 C 230.09 77.63 231.27 78.43 231.86 78.83 C 232.59 78.77 234.05 78.64 234.78 78.57 C 236.57 78.47 238.37 78.45 240.17 78.49 C 245.45 78.73 250.75 78.87 256.03 78.48 C 256.52 61.97 256.00 45.44 256.30 28.93 C 256.38 26.26 255.89 23.64 255.48 21.02 Z"
                    fill="#8a0623"
                    opacity="1.00"
                    stroke="#8a0623"
                    strokeWidth="0.25"
                    id="path6"
                />
                <path
                    d="M 246.58 32.53 C 248.68 29.36 250.63 26.05 253.31 23.32 C 252.94 24.66 252.67 26.04 252.26 27.37 C 251.11 28.55 249.85 29.64 248.98 31.05 C 241.22 43.84 235.97 58.21 233.91 73.03 C 233.51 74.94 234.30 76.77 234.78 78.57 C 234.05 78.64 232.59 78.77 231.86 78.83 C 231.27 78.43 230.09 77.63 229.49 77.24 C 232.94 61.61 238.26 46.26 246.58 32.53 Z"
                    fill="#cc1f36"
                    opacity="1.00"
                    stroke="#cc1f36"
                    strokeWidth="0.25"
                    id="path8"
                />
                <path
                    d="M 248.98 31.05 C 249.85 29.64 251.11 28.55 252.26 27.37 C 250.66 32.66 248.61 37.80 247.16 43.14 C 243.62 54.65 242.20 66.65 240.17 78.49 C 238.37 78.45 236.57 78.47 234.78 78.57 C 234.30 76.77 233.51 74.94 233.91 73.03 C 235.97 58.21 241.22 43.84 248.98 31.05 Z"
                    fill="#f76c6d"
                    opacity="1.00"
                    stroke="#f76c6d"
                    strokeWidth="0.25"
                    id="path10"
                />
                <path
                    d="m 287.14,125.95 c 0.05,14.93 -0.4,29.87 -1.53,44.76 -0.28,3.58 -0.72,7.15 -1.03,10.73 -8.33,-0.11 -16.67,-0.07 -25,-0.06 -0.68,-2.35 -1.54,-4.63 -2.35,-6.93 -0.39,-0.02 -1.17,-0.07 -1.56,-0.1 0.99,-12.29 0.26,-24.65 0.45,-36.98 4.97,-0.49 10.05,-1.99 13.64,-5.66 6.08,-5.53 8.37,-15 4.95,-22.56 -2.93,-7.39 -10.63,-12.29 -18.51,-12.38 l -0.53,0.02 c 0.86,-5.83 0.25,-11.74 0.55,-17.6 8.71,-0.01 17.41,-0.07 26.12,0.07 0.1523,-1.757848 4.45836,38.06626 4.8,46.69 z"
                    fill="#bbb3be"
                    opacity="1"
                    stroke="#bbb3be"
                    strokeWidth="0.25"
                    id="path12"
                />
                <path
                    d="m 228.78,79.24 0.79,-0.01 c 8.89,-0.09 17.77,0.01 26.65,-0.04 -0.3,5.86 0.31,11.77 -0.55,17.6 -7.06,0.29 -13.96,4.15 -17.44,10.36 -4.21,7.01 -3.46,16.55 1.96,22.72 3.85,4.82 9.94,7.01 15.93,7.5 -0.19,12.33 0.54,24.69 -0.45,36.98 -0.19,0.1 -0.56,0.29 -0.74,0.39 -1.17,2.08 -1.86,4.38 -2.34,6.71 -8.4,-0.02 -16.8,-0.06 -25.2,-0.04 -3.87964,-12.54096 1.56012,-14.09751 -0.8976,-10.41307 -1.24502,-4.43309 -1.63953,-25.06414 -2.12243,-36.11693 -0.15882,-4.48706 0.0665,-7.81177 0.0165,-11.80177 -0.08,-4.66 0.54353,-9.42823 0.74353,-14.07823 0.68,-9.99 2.91,-19.78 3.65,-29.76 z"
                    fill="#fef8eb"
                    opacity="1"
                    stroke="#fef8eb"
                    strokeWidth="0.25"
                    id="path14"
                />
                <path
                    d="M 238.23 107.15 C 241.71 100.94 248.61 97.08 255.67 96.79 L 256.20 96.77 C 256.12 97.56 255.96 99.13 255.88 99.92 C 251.00 100.97 245.90 102.56 242.71 106.67 C 235.57 114.61 239.74 128.84 249.69 132.27 C 251.55 133.28 253.55 132.44 255.42 131.95 L 256.17 131.84 C 256.14 133.69 256.13 135.53 256.12 137.37 C 250.13 136.88 244.04 134.69 240.19 129.87 C 234.77 123.70 234.02 114.16 238.23 107.15 Z"
                    fill="#37455d"
                    opacity="1.00"
                    stroke="#37455d"
                    strokeWidth="0.25"
                    id="path16"
                />
                <path
                    d="M 256.20 96.77 C 264.08 96.86 271.78 101.76 274.71 109.15 C 278.13 116.71 275.84 126.18 269.76 131.71 C 266.17 135.38 261.09 136.88 256.12 137.37 C 256.13 135.53 256.14 133.69 256.17 131.84 C 257.43 131.67 258.71 131.55 259.99 131.40 C 266.11 129.48 270.93 123.53 270.69 117.00 C 271.13 110.20 265.70 103.90 259.14 102.60 C 254.56 101.94 249.52 102.81 246.09 106.11 C 240.54 110.90 239.57 120.11 244.14 125.87 C 246.69 129.54 251.04 131.59 255.42 131.95 C 253.55 132.44 251.55 133.28 249.69 132.27 C 239.74 128.84 235.57 114.61 242.71 106.67 C 245.90 102.56 251.00 100.97 255.88 99.92 C 255.96 99.13 256.12 97.56 256.20 96.77 Z"
                    fill="#1a2847"
                    opacity="1.00"
                    stroke="#1a2847"
                    strokeWidth="0.25"
                    id="path18"
                />
                <path
                    d="M 246.09 106.11 C 249.52 102.81 254.56 101.94 259.14 102.60 C 258.43 103.28 257.72 103.95 257.01 104.63 C 260.52 105.92 264.24 107.39 266.35 110.68 C 270.15 115.50 267.99 122.81 263.51 126.39 C 260.95 127.89 257.97 128.41 255.22 129.43 C 256.71 130.30 258.32 130.96 259.99 131.40 C 258.71 131.55 257.43 131.67 256.17 131.84 L 255.42 131.95 C 251.04 131.59 246.69 129.54 244.14 125.87 C 239.57 120.11 240.54 110.90 246.09 106.11 Z"
                    fill="#d8e0fa"
                    opacity="1.00"
                    stroke="#d8e0fa"
                    strokeWidth="0.25"
                    id="path20"
                />
                <path
                    d="M 259.14 102.60 C 265.70 103.90 271.13 110.20 270.69 117.00 C 270.93 123.53 266.11 129.48 259.99 131.40 C 258.32 130.96 256.71 130.30 255.22 129.43 C 257.97 128.41 260.95 127.89 263.51 126.39 C 267.99 122.81 270.15 115.50 266.35 110.68 C 264.24 107.39 260.52 105.92 257.01 104.63 C 257.72 103.95 258.43 103.28 259.14 102.60 Z"
                    fill="#b4bede"
                    opacity="1.00"
                    stroke="#b4bede"
                    strokeWidth="0.25"
                    id="path22"
                />
                <path
                    d="M 254.27 113.36 C 255.79 112.20 257.67 112.98 259.16 113.78 C 261.11 116.60 259.10 122.37 255.09 120.89 C 251.64 120.22 251.30 114.82 254.27 113.36 Z"
                    fill="#fcfffb"
                    opacity="1.00"
                    stroke="#fcfffb"
                    strokeWidth="0.25"
                    id="path24"
                />
                <path
                    d="M 225.20 172.00 C 225.46 171.68 225.99 171.05 226.25 170.73 C 226.61 174.29 227.04 177.84 227.39 181.41 C 227.46 182.12 227.52 182.83 227.59 183.54 C 228.67 194.01 230.24 204.43 232.38 214.73 C 232.44 215.06 232.55 215.70 232.61 216.02 C 229.06 216.79 226.58 219.64 224.70 222.56 C 218.21 232.97 214.51 244.80 211.35 256.56 C 210.74 256.65 209.50 256.83 208.89 256.92 L 208.84 256.26 C 207.10 240.62 205.80 224.69 208.41 209.05 C 210.08 199.37 213.60 190.07 218.48 181.56 C 220.55 178.25 223.13 175.31 225.20 172.00 Z"
                    fill="#8b0623"
                    opacity="1.00"
                    stroke="#8b0623"
                    strokeWidth="0.25"
                    id="path26"
                />
                <path
                    d="M 284.58 181.44 C 284.89 177.86 285.33 174.29 285.61 170.71 C 295.18 179.25 302.31 190.55 305.33 203.05 C 309.48 219.69 309.07 237.43 304.34 253.89 C 303.74 255.15 303.65 257.23 301.93 257.50 C 300.22 256.14 300.11 253.85 299.51 251.92 C 296.76 241.97 293.20 232.14 287.85 223.26 C 286.14 220.51 284.12 217.71 280.99 216.48 C 280.61 216.28 279.85 215.88 279.47 215.68 C 281.51 205.03 283.49 194.35 284.41 183.54 C 284.45 183.01 284.54 181.96 284.58 181.44 Z"
                    fill="#890724"
                    opacity="1.00"
                    stroke="#890724"
                    strokeWidth="0.25"
                    id="path28"
                />
                <path
                    d="M 214.40 184.62 C 217.56 180.06 221.02 175.68 225.20 172.00 C 223.13 175.31 220.55 178.25 218.48 181.56 C 213.60 190.07 210.08 199.37 208.41 209.05 C 205.80 224.69 207.10 240.62 208.84 256.26 C 205.10 248.06 204.47 238.87 203.73 230.00 C 203.37 214.35 205.99 198.10 214.40 184.62 Z"
                    fill="#e65057"
                    opacity="1.00"
                    stroke="#e65057"
                    strokeWidth="0.25"
                    id="path30"
                />
                <path
                    d="M 255.67 174.35 C 256.06 174.38 256.84 174.43 257.23 174.45 C 258.04 176.75 258.90 179.03 259.58 181.38 C 259.72 181.90 260.01 182.95 260.15 183.48 C 262.69 196.25 263.52 209.33 262.93 222.33 C 262.85 224.80 262.56 227.26 262.24 229.72 C 262.06 230.94 261.89 232.16 261.72 233.39 C 261.41 235.64 261.16 237.89 260.86 240.15 C 260.82 240.45 260.74 241.06 260.70 241.37 C 259.76 247.08 258.64 252.76 257.14 258.35 C 256.36 258.43 255.58 258.52 254.80 258.60 C 253.85 255.47 253.31 252.24 252.73 249.03 C 253.36 250.38 254.33 251.39 255.65 252.06 C 254.42 252.73 253.96 254.64 255.04 255.65 C 256.57 255.47 256.23 253.33 256.33 252.21 C 256.86 228.47 256.40 204.73 256.54 181.00 C 256.70 178.77 255.90 176.69 254.93 174.74 C 255.11 174.64 255.48 174.45 255.67 174.35 Z"
                    fill="#8a0420"
                    opacity="1.00"
                    stroke="#8a0420"
                    strokeWidth="0.25"
                    id="path32"
                />
                <path
                    d="M 252.59 181.45 C 253.07 179.12 253.76 176.82 254.93 174.74 C 255.90 176.69 256.70 178.77 256.54 181.00 C 256.40 204.73 256.86 228.47 256.33 252.21 L 255.65 252.06 C 254.33 251.39 253.36 250.38 252.73 249.03 C 252.53 247.95 252.36 246.87 252.21 245.79 C 254.34 239.13 250.87 232.67 250.86 226.01 C 250.74 218.33 250.81 210.65 251.13 202.97 C 251.25 200.64 250.66 198.36 250.20 196.10 C 250.50 191.85 251.38 187.68 252.20 183.51 C 252.32 182.82 252.46 182.13 252.59 181.45 Z"
                    fill="#e61d34"
                    opacity="1.00"
                    stroke="#e61d34"
                    strokeWidth="0.25"
                    id="path34"
                />
                <path
                    d="M 227.39 181.41 C 235.79 181.39 244.19 181.43 252.59 181.45 C 252.46 182.13 252.32 182.82 252.20 183.51 C 244.00 183.58 235.79 183.49 227.59 183.54 C 227.52 182.83 227.46 182.12 227.39 181.41 Z"
                    fill="#282f3d"
                    opacity="1.00"
                    stroke="#282f3d"
                    strokeWidth="0.25"
                    id="path36"
                />
                <path
                    d="M 259.58 181.38 C 267.91 181.37 276.25 181.33 284.58 181.44 C 284.54 181.96 284.45 183.01 284.41 183.54 C 276.32 183.54 268.23 183.67 260.15 183.48 C 260.01 182.95 259.72 181.90 259.58 181.38 Z"
                    fill="#1a2645"
                    opacity="1.00"
                    stroke="#1a2645"
                    strokeWidth="0.25"
                    id="path38"
                />
                <path
                    d="M 260.15 183.48 C 268.23 183.67 276.32 183.54 284.41 183.54 C 283.49 194.35 281.51 205.03 279.47 215.68 C 279.85 215.88 280.61 216.28 280.99 216.48 C 277.90 216.23 278.76 220.17 277.88 222.19 C 272.90 222.13 267.91 222.07 262.93 222.33 C 263.52 209.33 262.69 196.25 260.15 183.48 Z"
                    fill="#bbb3be"
                    opacity="1.00"
                    stroke="#bbb3be"
                    strokeWidth="0.25"
                    id="path40"
                />
                <path
                    d="M 227.59 183.54 C 235.79 183.49 244.00 183.58 252.20 183.51 C 251.38 187.68 250.50 191.85 250.20 196.10 C 248.87 204.74 249.21 213.52 249.41 222.22 C 244.30 222.09 239.18 222.13 234.06 222.19 C 232.98 219.92 234.17 217.96 234.72 215.89 C 234.13 215.60 232.96 215.02 232.38 214.73 C 230.24 204.43 228.67 194.01 227.59 183.54 Z"
                    fill="#fff8ec"
                    opacity="1.00"
                    stroke="#fff8ec"
                    strokeWidth="0.25"
                    id="path42"
                />
                <path
                    d="M 249.41 222.22 C 249.21 213.52 248.87 204.74 250.20 196.10 C 250.66 198.36 251.25 200.64 251.13 202.97 C 250.81 210.65 250.74 218.33 250.86 226.01 C 250.87 232.67 254.34 239.13 252.21 245.79 C 251.84 243.75 251.50 241.71 251.18 239.67 L 251.07 238.94 C 250.77 237.08 250.46 235.23 250.13 233.38 C 249.35 229.71 249.48 225.95 249.41 222.22 Z"
                    fill="#cc1f36"
                    opacity="1.00"
                    stroke="#cc1f36"
                    strokeWidth="0.25"
                    id="path44"
                />
                <path
                    d="M 234.06 222.19 C 239.18 222.13 244.30 222.09 249.41 222.22 C 249.48 225.95 249.35 229.71 250.13 233.38 C 245.72 233.42 241.32 233.33 236.91 233.37 C 235.79 233.38 234.67 233.40 233.56 233.44 C 233.03 232.22 232.77 230.96 232.77 229.64 C 233.54 229.62 235.08 229.58 235.85 229.56 C 235.23 227.10 234.62 224.65 234.06 222.19 Z"
                    fill="#394662"
                    opacity="1.00"
                    stroke="#394662"
                    strokeWidth="0.25"
                    id="path46"
                />
                <path
                    d="M 262.93 222.33 C 267.91 222.07 272.90 222.13 277.88 222.19 C 277.45 224.24 276.90 226.26 276.36 228.29 C 272.07 230.75 266.92 229.43 262.24 229.72 C 262.56 227.26 262.85 224.80 262.93 222.33 Z"
                    fill="#1a2645"
                    opacity="1.00"
                    stroke="#1a2645"
                    strokeWidth="0.25"
                    id="path48"
                />
                <path
                    d="M 262.24 229.72 C 266.92 229.43 272.07 230.75 276.36 228.29 C 276.37 228.58 276.40 229.18 276.41 229.48 C 279.11 228.99 278.85 231.45 278.29 233.27 C 276.83 233.30 275.38 233.33 273.93 233.36 C 269.86 233.42 265.79 233.38 261.72 233.39 C 261.89 232.16 262.06 230.94 262.24 229.72 Z"
                    fill="#414c67"
                    opacity="1.00"
                    stroke="#414c67"
                    strokeWidth="0.25"
                    id="path50"
                />
                <g className="smoke-2">
                    <path
                        d="M 236.91 233.37 C 241.32 233.33 245.72 233.42 250.13 233.38 C 250.46 235.23 250.77 237.08 251.07 238.94 C 250.52 237.39 249.87 235.87 249.25 234.35 C 247.35 234.66 243.62 234.00 243.73 236.90 C 244.17 242.25 243.94 247.61 243.81 252.97 C 244.45 259.86 243.02 266.82 244.48 273.64 C 245.29 279.19 248.05 284.13 250.73 288.96 C 250.57 301.31 251.04 313.69 249.93 326.02 C 249.81 335.86 248.09 345.63 245.26 355.05 C 243.28 363.45 239.40 371.32 234.37 378.31 C 223.68 382.08 212.53 387.54 206.80 397.84 C 204.87 400.53 202.20 402.77 201.11 406.01 C 199.35 410.85 197.85 415.84 197.23 420.97 C 196.76 424.48 197.27 428.28 195.30 431.42 C 190.92 427.81 185.34 426.56 179.95 425.35 C 178.58 424.76 176.17 424.95 176.05 422.97 C 175.77 421.63 175.59 420.28 175.51 418.92 C 178.93 412.57 184.17 407.34 188.85 401.89 C 189.87 400.76 189.09 399.19 188.99 397.90 C 187.40 390.12 188.57 381.74 193.10 375.13 C 198.40 368.23 206.42 363.53 214.93 361.97 C 216.95 356.33 220.97 351.63 222.64 345.84 C 229.62 322.42 233.19 298.10 234.78 273.75 C 236.10 266.90 235.00 259.90 236.07 253.04 C 236.91 246.51 235.91 239.88 236.91 233.37 Z"
                        fill="#ffe6cb"
                        opacity="1.00"
                        stroke="#ffe6cb"
                        strokeWidth="0.25"
                        id="path52"
                    />
                    <path
                        d="M 260.86 240.15 C 261.16 237.89 261.41 235.64 261.72 233.39 C 265.79 233.38 269.86 233.42 273.93 233.36 C 274.98 237.15 274.44 241.10 274.72 244.97 C 276.03 256.95 274.79 269.06 276.17 281.04 C 276.42 292.43 277.73 303.75 279.19 315.04 C 280.24 323.54 282.12 332.17 286.57 339.60 C 291.98 348.38 302.22 352.89 307.06 362.11 C 310.01 366.83 311.22 372.44 311.21 377.95 C 310.56 382.88 309.91 387.89 310.61 392.85 C 312.89 400.71 321.07 405.53 329.00 405.47 C 342.46 405.68 355.02 413.68 362.21 424.83 C 365.56 430.73 367.67 437.30 368.71 444.00 C 368.23 444.23 367.74 444.44 367.24 444.62 C 364.10 442.12 361.99 438.61 359.11 435.85 C 345.05 421.48 321.63 417.53 303.68 426.70 C 301.74 427.31 299.79 427.87 297.84 428.44 C 297.54 422.98 297.95 417.31 295.91 412.13 C 294.24 407.59 290.91 403.93 287.49 400.63 C 287.58 400.00 287.77 398.73 287.87 398.10 C 282.38 392.09 276.48 386.07 268.83 382.88 C 263.09 380.51 257.27 377.78 250.95 377.56 C 245.40 377.17 239.92 378.57 234.37 378.31 C 239.40 371.32 243.28 363.45 245.26 355.05 C 248.09 345.63 249.81 335.86 249.93 326.02 C 251.04 313.69 250.57 301.31 250.73 288.96 C 251.91 290.42 252.70 292.95 254.95 292.92 C 258.70 292.86 260.75 289.24 262.25 286.30 C 264.66 281.11 267.05 275.74 267.65 270.00 C 268.48 258.44 267.67 246.85 267.74 235.28 C 266.15 235.06 264.57 234.83 263.00 234.61 C 262.23 236.43 261.47 238.26 260.86 240.15 Z"
                        fill="#ffd4b1"
                        opacity="1.00"
                        stroke="#ffd4b1"
                        strokeWidth="0.25"
                        id="path54"
                    />
                </g>
                <path
                    d="M 243.73 236.90 C 243.62 234.00 247.35 234.66 249.25 234.35 C 249.87 235.87 250.52 237.39 251.07 238.94 L 251.18 239.67 C 249.94 244.67 248.02 249.61 248.25 254.84 C 248.52 262.48 248.18 270.51 251.51 277.59 C 252.33 280.15 256.05 280.74 257.80 278.83 C 260.83 275.70 262.90 271.46 262.91 267.05 C 263.00 261.39 262.67 255.73 262.71 250.07 C 262.77 247.04 261.76 244.16 260.70 241.37 C 260.74 241.06 260.82 240.45 260.86 240.15 C 261.47 238.26 262.23 236.43 263.00 234.61 C 264.57 234.83 266.15 235.06 267.74 235.28 C 267.67 246.85 268.48 258.44 267.65 270.00 C 267.05 275.74 264.66 281.11 262.25 286.30 C 260.75 289.24 258.70 292.86 254.95 292.92 C 252.70 292.95 251.91 290.42 250.73 288.96 C 248.05 284.13 245.29 279.19 244.48 273.64 C 243.02 266.82 244.45 259.86 243.81 252.97 C 243.94 247.61 244.17 242.25 243.73 236.90 Z"
                    fill="#ffe58f"
                    opacity="1.00"
                    stroke="#ffe58f"
                    strokeWidth="0.25"
                />
                <path
                    d="M 251.18 239.67 C 251.50 241.71 251.84 243.75 252.21 245.79 C 252.36 246.87 252.53 247.95 252.73 249.03 C 253.31 252.24 253.85 255.47 254.80 258.60 C 255.58 258.52 256.36 258.43 257.14 258.35 C 258.64 252.76 259.76 247.08 260.70 241.37 C 261.76 244.16 262.77 247.04 262.71 250.07 C 262.67 255.73 263.00 261.39 262.91 267.05 C 262.90 271.46 260.83 275.70 257.80 278.83 C 256.05 280.74 252.33 280.15 251.51 277.59 C 248.18 270.51 248.52 262.48 248.25 254.84 C 248.02 249.61 249.94 244.67 251.18 239.67 Z"
                    fill="#fff59c"
                    opacity="1.00"
                    stroke="#fff59c"
                    strokeWidth="0.25"
                />
                <path
                    d="M 243.73 236.90 C 243.62 234.00 247.35 234.66 249.25 234.35 C 249.87 235.87 250.52 237.39 251.07 238.94 L 251.18 239.67 C 249.94 244.67 248.02 249.61 248.25 254.84 C 248.52 262.48 248.18 270.51 251.51 277.59 C 252.33 280.15 256.05 280.74 257.80 278.83 C 260.83 275.70 262.90 271.46 262.91 267.05 C 263.00 261.39 262.67 255.73 262.71 250.07 C 262.77 247.04 261.76 244.16 260.70 241.37 C 260.74 241.06 260.82 240.45 260.86 240.15 C 261.47 238.26 262.23 236.43 263.00 234.61 C 264.57 234.83 266.15 235.06 267.74 235.28 C 267.67 246.85 268.48 258.44 267.65 270.00 C 267.05 275.74 264.66 281.11 262.25 286.30 C 260.75 289.24 258.70 292.86 254.95 292.92 C 252.70 292.95 251.91 290.42 250.73 288.96 C 248.05 284.13 245.29 279.19 244.48 273.64 C 243.02 266.82 244.45 259.86 243.81 252.97 C 243.94 247.61 244.17 242.25 243.73 236.90 Z"
                    fill="#ffe58f"
                    opacity="1.00"
                    stroke="#ffe58f"
                    strokeWidth="0.25"
                    className="inner-fire"
                />
                <path
                    d="M 251.18 239.67 C 251.50 241.71 251.84 243.75 252.21 245.79 C 252.36 246.87 252.53 247.95 252.73 249.03 C 253.31 252.24 253.85 255.47 254.80 258.60 C 255.58 258.52 256.36 258.43 257.14 258.35 C 258.64 252.76 259.76 247.08 260.70 241.37 C 261.76 244.16 262.77 247.04 262.71 250.07 C 262.67 255.73 263.00 261.39 262.91 267.05 C 262.90 271.46 260.83 275.70 257.80 278.83 C 256.05 280.74 252.33 280.15 251.51 277.59 C 248.18 270.51 248.52 262.48 248.25 254.84 C 248.02 249.61 249.94 244.67 251.18 239.67 Z"
                    fill="#fff59c"
                    opacity="1.00"
                    stroke="#fff59c"
                    strokeWidth="0.25"
                    className="main-fire"
                />
                <path
                    d="M 255.65 252.06 L 256.33 252.21 C 256.23 253.33 256.57 255.47 255.04 255.65 C 253.96 254.64 254.42 252.73 255.65 252.06 Z"
                    fill="#fc646f"
                    opacity="1.00"
                    stroke="#fc646f"
                    strokeWidth="0.25"
                    id="path60"
                />

                <g className="smoke-1">
                    <path
                        d="M 206.80 397.84 C 212.53 387.54 223.68 382.08 234.37 378.31 C 239.92 378.57 245.40 377.17 250.95 377.56 C 257.27 377.78 263.09 380.51 268.83 382.88 C 276.48 386.07 282.38 392.09 287.87 398.10 C 287.77 398.73 287.58 400.00 287.49 400.63 C 275.80 391.36 259.84 387.75 245.33 391.34 C 234.69 393.64 225.21 400.15 218.77 408.86 C 211.42 418.50 208.81 431.79 212.76 443.33 C 214.34 448.09 217.76 451.85 220.53 455.93 C 219.16 456.21 217.80 456.69 216.39 456.69 C 212.48 453.63 209.33 449.67 205.24 446.81 C 191.39 436.94 172.37 434.93 156.79 441.79 C 148.40 445.80 140.57 451.42 135.16 459.10 C 133.07 460.49 130.76 463.22 128.01 462.01 C 127.43 461.83 126.28 461.48 125.71 461.31 C 127.33 460.82 129.99 461.82 130.77 459.82 C 134.49 451.91 137.88 443.49 144.58 437.54 C 152.02 430.01 162.52 426.94 172.73 425.58 C 173.65 423.35 174.59 421.14 175.51 418.92 C 175.59 420.28 175.77 421.63 176.05 422.97 C 176.17 424.95 178.58 424.76 179.95 425.35 C 185.34 426.56 190.92 427.81 195.30 431.42 C 197.27 428.28 196.76 424.48 197.23 420.97 C 197.85 415.84 199.35 410.85 201.11 406.01 C 202.20 402.77 204.87 400.53 206.80 397.84 Z"
                        fill="#ebe3d9"
                        opacity="1.00"
                        stroke="#ebe3d9"
                        strokeWidth="0.25"
                        id="path62"
                    />
                    <path
                        d="M 245.33 391.34 C 259.84 387.75 275.80 391.36 287.49 400.63 C 290.91 403.93 294.24 407.59 295.91 412.13 C 297.95 417.31 297.54 422.98 297.84 428.44 C 284.98 431.50 272.93 442.61 273.09 456.56 C 268.27 456.62 264.54 452.94 259.92 452.10 C 251.78 450.37 243.24 448.98 235.00 450.88 C 230.01 451.99 224.70 452.74 220.53 455.93 C 217.76 451.85 214.34 448.09 212.76 443.33 C 208.81 431.79 211.42 418.50 218.77 408.86 C 225.21 400.15 234.69 393.64 245.33 391.34 Z"
                        fill="#cbbeb6"
                        opacity="1.00"
                        stroke="#cbbeb6"
                        strokeWidth="0.25"
                        id="path64"
                    />
                    <path
                        d="M 303.68 426.70 C 321.63 417.53 345.05 421.48 359.11 435.85 C 361.99 438.61 364.10 442.12 367.24 444.62 C 367.74 444.44 368.23 444.23 368.71 444.00 C 376.76 433.14 394.88 433.45 403.11 443.91 C 410.60 451.67 410.77 464.83 404.04 473.12 C 413.66 473.11 423.58 480.15 424.96 489.97 C 411.28 490.13 397.59 489.99 383.91 490.04 C 385.16 481.83 384.43 472.68 378.98 466.02 C 374.58 461.06 368.86 457.05 362.34 455.54 C 358.53 454.65 354.60 454.60 350.71 454.41 C 349.23 445.52 343.67 437.51 335.79 433.12 C 326.26 427.16 314.69 425.88 303.68 426.70 Z"
                        fill="#bfadab"
                        opacity="1.00"
                        stroke="#bfadab"
                        strokeWidth="0.25"
                        id="path66"
                    />
                    <path
                        d="M 303.68 426.70 C 314.69 425.88 326.26 427.16 335.79 433.12 C 343.67 437.51 349.23 445.52 350.71 454.41 C 354.60 454.60 358.53 454.65 362.34 455.54 C 368.86 457.05 374.58 461.06 378.98 466.02 C 384.43 472.68 385.16 481.83 383.91 490.04 C 333.43 490.02 282.95 490.16 232.48 489.93 C 234.97 487.50 237.75 485.25 241.09 484.11 C 246.03 482.09 251.45 482.59 256.65 482.14 C 257.18 474.98 260.00 467.39 266.35 463.46 C 269.10 461.66 273.75 460.83 273.09 456.56 C 272.93 442.61 284.98 431.50 297.84 428.44 C 299.79 427.87 301.74 427.31 303.68 426.70 Z"
                        fill="#a5999b"
                        opacity="1.00"
                        stroke="#a5999b"
                        strokeWidth="0.25"
                        id="path68"
                    />
                    <path
                        d="M 156.79 441.79 C 172.37 434.93 191.39 436.94 205.24 446.81 C 209.33 449.67 212.48 453.63 216.39 456.69 C 217.80 456.69 219.16 456.21 220.53 455.93 C 216.75 458.34 212.52 460.12 209.23 463.24 C 205.94 466.02 203.43 469.53 200.93 473.00 C 199.61 475.20 196.74 474.98 194.61 475.79 C 191.27 476.42 189.33 479.43 187.20 481.77 C 184.75 478.65 182.42 475.26 178.97 473.17 C 169.24 467.62 156.01 467.97 147.26 475.28 C 142.61 478.99 140.09 484.50 138.14 489.97 C 134.52 489.92 130.90 489.94 127.29 490.03 C 127.85 484.08 127.66 478.00 129.19 472.18 C 130.35 467.48 132.82 463.28 135.16 459.10 C 140.57 451.42 148.40 445.80 156.79 441.79 Z"
                        fill="#d6cbc3"
                        opacity="1.00"
                        stroke="#d6cbc3"
                        strokeWidth="0.25"
                        id="path70"
                    />
                    <path
                        d="M 235.00 450.88 C 243.24 448.98 251.78 450.37 259.92 452.10 C 264.54 452.94 268.27 456.62 273.09 456.56 C 273.75 460.83 269.10 461.66 266.35 463.46 C 260.00 467.39 257.18 474.98 256.65 482.14 C 251.45 482.59 246.03 482.09 241.09 484.11 C 237.75 485.25 234.97 487.50 232.48 489.93 C 201.03 490.24 169.59 489.91 138.14 489.97 C 140.09 484.50 142.61 478.99 147.26 475.28 C 156.01 467.97 169.24 467.62 178.97 473.17 C 182.42 475.26 184.75 478.65 187.20 481.77 C 189.33 479.43 191.27 476.42 194.61 475.79 C 196.74 474.98 199.61 475.20 200.93 473.00 C 203.43 469.53 205.94 466.02 209.23 463.24 C 212.52 460.12 216.75 458.34 220.53 455.93 C 224.70 452.74 230.01 451.99 235.00 450.88 Z"
                        fill="#c0aeac"
                        opacity="1.00"
                        stroke="#c0aeac"
                        strokeWidth="0.25"
                        id="path72"
                    />
                    <path
                        d="M 128.01 462.01 C 130.76 463.22 133.07 460.49 135.16 459.10 C 132.82 463.28 130.35 467.48 129.19 472.18 C 127.66 478.00 127.85 484.08 127.29 490.03 C 109.87 490.16 92.45 489.70 75.03 490.06 C 75.26 483.05 78.57 476.40 83.16 471.23 C 93.89 460.02 111.16 456.94 125.71 461.31 C 126.28 461.48 127.43 461.83 128.01 462.01 Z"
                        fill="#e3d9d0"
                        opacity="1.00"
                        stroke="#e3d9d0"
                        strokeWidth="0.25"
                        id="path74"
                    />
                </g>
            </g>
        </svg>
    );
}
